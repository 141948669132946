import React from "react";
import "./About.css";

const About = () => {
  return (
    <div id="about" className="about-container">
      <div className="about-content">
        <div className="about-intro-content">
          <div className="about-intro">
            <h2>About Me</h2>
            <p>
              I'm Faraz Ahmed, a passionate React Native and ReactJS developer
              with a focus on building high-performance mobile and web
              applications. With a keen eye for detail, I thrive in creating
              solutions that are both functional and user-friendly.
            </p>
          </div>
          <div className="contact-section">
            <h3>Contact Me</h3>
            <p>Feel free to reach out for collaborations or any queries!</p>
            <a href="#contact" className="contact-btn">
              Get In Touch
            </a>
          </div>
        </div>
        <div className="about-skills">
          <ul className="about-list">
            <li>React Native</li>
            <li>ReactJS</li>
            <li>JavaScript (ES6+)</li>
            <li>HTML & CSS</li>
            <li>Node.js</li>
            <li>SQLite</li>
            <li>Git & GitHub</li>
            <li>Redux</li>
            <li>Context API</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default About;
