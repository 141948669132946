import React from "react";
import "./Education.css";

const Education = () => {
  return (
    <div id="education" className="education-container">
      <h1>Education</h1>
      <div className="education-details">
        <div className="education-item">
          <h3>Bachelor of Telecommunication Engineering</h3>
          <p>Quaid-e-Awam University of Engineering, Science & Technology</p>
          <p>2017 - 2021</p>
          <p>Achievements: First Position in Final Year Project.</p>
        </div>
        <div className="education-item">
          <h3>Intermediate in Pre-Engineering</h3>
          <p>F.G College Hyderabad</p>
          <p>2015 - 2017</p>
          <p>Activities: Rugby, Cricket Team Captain.</p>
        </div>
        <div className="education-item">
          <h3>Matriculation in Science</h3>
          <p>St Paul's High School, Hyderabad</p>
          <p>2014 - 2015</p>
          <p>Achievements: A+ Grade, Position Holder</p>
        </div>
      </div>
    </div>
  );
};

export default Education;
