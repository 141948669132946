import React, { useState } from "react";
import './NavBar.css';
import profileImage from '../../assets/profileImage.jpg'; 

const Navbar = () => {
  const [activeTab, setActiveTab] = useState('home');
  const [isOpen, setIsOpen] = useState(false);

  const handleNavClick = (tab) => {
    setActiveTab(tab);
    setIsOpen(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={profileImage} alt="Logo" className="navbar-logo-img" />
        <h2>Faraz Ahmed</h2>
      </div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <a 
          href="#home" 
          className={activeTab === 'home' ? 'active' : ''} 
          onClick={() => handleNavClick('home')}
        >
          Home
        </a>
        <a 
          href="#about" 
          className={activeTab === 'about' ? 'active' : ''} 
          onClick={() => handleNavClick('about')}
        >
          About
        </a>
        <a 
          href="#education" 
          className={activeTab === 'education' ? 'active' : ''} 
          onClick={() => handleNavClick('education')}
        >
          Education
        </a>
        <a 
          href="#projects" 
          className={activeTab === 'projects' ? 'active' : ''} 
          onClick={() => handleNavClick('projects')}
        >
          Projects
        </a>
        <a 
          href="#contact" 
          className={activeTab === 'contact' ? 'active' : ''} 
          onClick={() => handleNavClick('contact')}
        >
          Contact
        </a>
      </div>
      <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
    </nav>
  );
};

export default Navbar;