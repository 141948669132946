import React from "react";
import "./Home.css";
import myImage from "../../assets/profileImage.jpg";

const Home = () => {
  return (
    <div id="home" className="home-container">
      <div className="home-content">
        <div className="home-image">
          <img src={myImage} alt="Faraz Ahmed" />
        </div>
        <div className="home-text">
          <h1>Hey, I'M FARAZ AHMED</h1>
          <p className="profession">
            I'm a React Native and React JS Developer
          </p>
          <p className="summary">
            A passionate React Native and ReactJS Developer focused on building
            high-performance mobile and web applications that provide excellent
            user experiences and contribute to the success of innovative digital
            products.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
