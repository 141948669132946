import React from 'react';
import Home from './src/pages/home/Home';
import Navbar from './src/components/navBar/NavBar';
import About from './src/pages/about/About';
import './App.css'
import Projects from './src/pages/projects/Projects';
import Contact from './src/pages/contact/Contact';
import Education from './src/pages/education/Education';

function App() {
  return (
    <div className="App">
      <Navbar/>
      <Home/>
      <About/>
      <Education/>
      <Projects/>
      <Contact/>
    </div>
  );
}

export default App;