import React from "react";
import "./Projects.css";
import healthApp from "../../assets/healthApp.png";
import chatApp from "../../assets/chatApp.png";
import managementApp from "../../assets/managementApp.png";

const Projects = () => {
  return (
    <div id="projects" className="projects-container">
      <h1>Projects</h1>
      <p className="projects-intro">
        Here you will find some of the personal and client projects that I
        created, each project containing its own case study.
      </p>

      <div className="projects-grid">
        {/* Project 1 */}
        <div className="project-item">
          <img
            src={healthApp}
            alt="Doctor/Patient Mobile App"
            className="project-img"
          />
          <div className="project-details">
            <h3>Doctor/Patient Mobile App</h3>
            <h4>(Android-IOS)</h4>
            <ul>
              <li>
                This doctor-patient app serves as a comprehensive virtual care
                platform.
              </li>
              <li>
                Enables patients to connect with healthcare professionals
                conveniently and efficiently.
              </li>
              <li>
                Patients can input their symptoms and be matched with relevant
                doctors.
              </li>
              <li>
                Patients can book appointments, ensuring timely and organized
                consultations.
              </li>
              <li>
                During scheduled sessions, doctors and patients can engage in
                secure video calls.
              </li>
              <li>
                Doctors can assess symptoms, provide medical advice, and issue
                prescriptions directly.
              </li>
              <li>
                Creates a seamless healthcare experience focused on
                accessibility and quality.
              </li>
              <li>
                Revolutionizes traditional healthcare by making professional
                medical support accessible from anywhere.
              </li>
            </ul>
          </div>
        </div>

        {/* Project 2 */}
        <div className="project-item">
          <img src={chatApp} alt="E-commerce Website" className="project-img" />
          <div className="project-details">
            <h3>Chat Application</h3>
            <h4>(Android-IOS)</h4>
            <p>
              Real-time messaging with audio and video calling capabilities.
              User-friendly interface for easy navigation and communication.
              High-quality voice and video call options for seamless
              interaction. Allows users to share media, including images and
              videos. Secure and private, with a focus on data protection.
              Versatile for both personal and professional use.
            </p>
          </div>
        </div>

        {/* Project 3 */}
        <div className="project-item">
          <img
            src={managementApp}
            alt="Inventory Management System"
            className="project-img"
          />
          <div className="project-details">
            <h3>Employee Management System</h3>
            <ul>
              <li>
                Designed for two user types with distinct interfaces: Admin and
                Employee.
              </li>
              <li>
                Admin Interface:
                <ul>
                  <li>Full access to the system's features and controls.</li>
                  <li>
                    Can view and manage employee attendance records and details.
                  </li>
                  <li>Ability to add, update, and remove employee profiles.</li>
                  <li>
                    Dashboard for monitoring overall attendance, leaves, and
                    performance metrics.
                  </li>
                </ul>
              </li>
              <li>
                Employee Interface:
                <ul>
                  <li>Limited access focused on attendance tracking.</li>
                  <li>Options to log sign-in and sign-out times.</li>
                  <li>Ability to view personal attendance history.</li>
                </ul>
              </li>
              <li>
                Secure access with user-specific permissions, ensuring data
                integrity and privacy.
              </li>
              <li>
                Efficient system for managing employee attendance and
                performance in real time.
              </li>
            </ul>
          </div>
        </div>

        {/* Project 4 */}
        {/* <div className="project-item">
          <img src={project4} alt="Portfolio Website" className="project-img" />
          <div className="project-details">
            <h3>Portfolio Website</h3>
            <p>A personal portfolio website showcasing my skills, projects, and experience, with interactive design and responsive layout.</p>
          </div>
        </div> */}

        {/* Project 5 */}
        {/* <div className="project-item">
          <img src={project5} alt="Social Media App" className="project-img" />
          <div className="project-details">
            <h3>Social Media App</h3>
            <p>A social media application enabling users to post updates, share images, follow other users, and engage through likes and comments.</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Projects;
